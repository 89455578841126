const processUniversalLinkMode = () => {
  const isRedirectToStore = window.location.search.includes('to_store')
  if (isRedirectToStore) {
    redirectToStore()
  } else {
    window.location.href = `${process.env.VUE_APP_DEEP_LINK_DOMAIN}:/${window.location.pathname}`
    setTimeout(() => {
      window.location.href = `${window.location.pathname}?to_store=1`
    }, 1000)
  }
}

const redirectToStore = () => {
  /**
   * Determine the mobile operating system.
   * This function returns one of 'iOS', 'Android', 'Windows Phone', or 'unknown'.
   *
   * @returns {String}
   */
  const getMobileOperatingSystem = () => {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return 'Windows Phone'
    }

    if (/android/i.test(userAgent)) {
      return 'Android'
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    var isMacLike = /(Mac|iPhone|iPod|iPad)/i.test(userAgent)
    var isIOS = /(iPhone|iPod|iPad)/i.test(userAgent)
    if ((isIOS || isMacLike) && !window.MSStream) {
      return 'iOS'
    }

    return 'unknown'
  }

  var platform = getMobileOperatingSystem()
  if (platform === 'iOS') {
    window.location.href = 'itms-apps://itunes.apple.com/app/id1569125239'
  // } else if (platform === 'Android') {
  } else {
    window.location.href = 'https://play.google.com/store/apps/details?id=tech.makea.apps'
  }
}

export {
  processUniversalLinkMode,
}
